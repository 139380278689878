import React from "react";
import { graphql } from "gatsby";
import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Carousel from "../components/carousel-v2";
import 'normalize.css'
import '../styles/global.css'

class HomeIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="Home Page"
          description="Web Developer, YouTuber, Entrepreneur, Online Analytics-er | New York City and Hoboken"
        />
        <Bio
          heading="Chris Shimmin | New York City"
          subheading="Web Developer, YouTuber, Entrepreneur, Online Analytics-er"
        />
        <Carousel key="website" title="Websites" data={data.website.edges} />
        <Carousel key="youtube" title="YouTube" data={data.youtube.edges} />
      </Layout>
    );
  }
}

export default HomeIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    website: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "website" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            title
            description
            url {
              childImageSharp {
                gatsbyImageData(
                  width: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          id
        }
      }
    }
    youtube: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "youtube" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            title
            description
            url {
              childImageSharp {
                gatsbyImageData(
                  width: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          id
        }
      }
    }
  }
`;
